<template>
    <div>
        <el-dialog :title="title" :visible.sync="open" width="600px" @close="commitOnClose" :rules="rules">
            <el-form ref="saveOrEditForm" :model="saveData" label-width="120px">
                <el-form-item label="原SN号" prop="origSn">
                    <el-input v-model="saveData.origSn"
                              placeholder="请输入原SN号"/>
                </el-form-item>
                <el-form-item label="返修后SN号" prop="reworkSn">
                    <el-input v-model="saveData.reworkSn"
                              placeholder="请输入返修后SN号"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">提交</el-button>
                <el-button type="primary" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {TerminalApi} from '@/api';
    export default {
        name: "CustomForm",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            isAdd: {
                type: Boolean,
                default: true,
            },
            selectRow: {
                type: Object,
                default: function () {
                    return {}
                },
            },
            onFresh: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                title: '',
                saveData: {},
                open: false,
                rules: {
                    origSn: [{ required: true, message: '请输入原SN号', trigger: 'blur' }],
                    reworkSn: [{ required: true, message: '请输入返修后SN号', trigger: 'blur' }],
                }
            }
        },
        watch: {
            onFresh: function (value) {
                this.open = value;
                if (value) {
                    this.resetData();
                }
            }
        },
        mounted() {
        },
        methods: {
            async resetData() {
                if (this.isAdd){
                    this.title = "添加返修映射";
                    this.saveData = {};
                }else {
                    this.saveData = this.selectRow;
                    this.isAdd = false;
                    this.title = "修改返修映射";
                }
            },
            async submitForm() {
                let result = (this.isAdd) ?
                        (await TerminalApi.reworkMapping.save(this.saveData)) :
                        (await TerminalApi.reworkMapping.edit(this.saveData.id, this.saveData));
                if (result.success) {
                    this.Message.success(this.isAdd ? '添加成功' : '修改成功');
                    this.commitOnClose();
                } else{
                    this.Message.error(result.message);
                }
            },
            cancel() {
                this.saveData = {};
                this.commitOnClose();
            },
            commitOnClose() {
                this.$emit('on-close');
            }
        }
    };
</script>

<style scoped>

</style>
